import axios from 'axios';
import md5 from 'md5';
import { checkErrorResponse } from '../utils/helpers';

export const login = payload => {
  payload.password = md5(payload.password);

  let login = payload.login;
  document.cookie = `appilix_push_notification_user_identity=${login}`;

  return axios
    .post(`${process.env.REACT_APP_API}/authentication/`, payload)
    .then(function (response) {
      return response.data;
    })
    .catch(error => {
     return checkErrorResponse(error.response);
    });
};

export const recover = payload => {
  if (payload['new-password'])
    payload['new-password'] = md5(payload['new-password']);

  return axios
    .post(`${process.env.REACT_APP_API}/recover/`, payload)
    .then(function (response) {
      return response.data;
    })
    .catch(error => {
      return checkErrorResponse(error.response);
    });
};

export const mobileCheck = (type, mobile) => {
  return axios
    .get(`${process.env.REACT_APP_API}/validate/${type}/${mobile}`)
    .then(function (response) {
      return response.data;
    })
    .catch(error => {
      return checkErrorResponse(error.response);
    });
};
