import React, { useState, useEffect, useContext } from 'react';
import { show as showFile } from '../../services/file';
import { UserContext } from '../../context/UserProvider';
import ReactPanZoom from 'react-image-pan-zoom-rotate';
import { isDesktop } from 'react-device-detect';

const App = ({ id, width = '100%', height = '100%' }) => {
  const { token } = useContext(UserContext);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    if (id) {
      loadFile();
    }
  }, [id]);

  const loadFile = () => {
    showFile(token, id).then(res => {
      let url = res?.data?.file || null;

      if (!url.endsWith('.heic')) {
        setUrl(url );
        return;
      }
      
      setUrl('INVALID');
    });
  };

  if (!url) {
    return null;
  }

  if (url === 'INVALID') {
    return <div>Invalid file, please upload JPEG</div>;
  }

  if (!url.startsWith('http') && !url.startsWith('https')) {
    return null;
  }

  if (isDesktop) {
    return (
      <div
    style={{
      width: width,
      height: height,
      position: "relative",
      overflow: "hidden"
    }}
  >
      <ReactPanZoom
        image={url}
      />
      </div>
    );
  }

  return (
    <>
      {url && (
        <a href={url} target="_blank">
          <img
            src={url}
            style={{
              width: width,
              height: height,
              objectFit: 'contain',
            }}
          />
        </a>
      )}
    </>
  );
};

export default App;
